function setOptionsActive() {
    if (state !== undefined) {
        for (const [step, options] of Object.entries(state['steps'])) {
            for (const [option_key, option] of Object.entries(options)) {
                if ( step == 1) {
                    if (option.active == 1) {
                        setOptionActive('.step_' + step + ' button[value="' + option_key + '"]');
                    }
                } else {
                    if (option.active == 1) {
                        setOptionActive('.step_' + step + ' a[data-value="' + option_key + '"]');
                    }
                }
            }
        }
    }
}

function setOptionActive(selector) {
    $(selector).addClass('active');
    $(selector + " input").prop( "checked", true );
}

function setOptionInactive(selector) {
    $(selector).removeClass('active');
    $(selector + " input").prop( "checked", false );
}

function recalculatePrices() {
    if (state !== undefined) {
        var additionalPrice = 0;
        state['additional_costs'] = 0;
        for (const [step, options] of Object.entries(state['steps'])) {
            for (const [option_key, option] of Object.entries(options)) {
                if (option.active == 1) {
                    if (option.price != "1.00") {
                        additionalPrice += parseFloat(option.price);
                    } else {
                        state['additional_costs']= 1;
                    }
                }
            }
        }
        $('#base_price').html(formatPrice(state['base_price']));
        $('#surcharge_price').html(formatPrice(additionalPrice));
        $('#total_price').html(formatPrice(additionalPrice + parseFloat(state['base_price'])));
        if (state['additional_costs'] == 1) {
            $('.additional_costs').css('display','block');
        } else {
            $('.additional_costs').css('display','none');
        }
        state['total_price'] = additionalPrice + parseFloat(state['base_price']);
        state['surcharge_price'] = additionalPrice;
    }
}

function saveState(link, apartment_slug) {
    $.ajax({
        method: "post",
        url: "/" + apartment_slug + "/savestate",
        data: state,
        headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
    }).done(function( msg ) {
        if (msg === 'saved') {
            location.href = link;
        } else {
            location.href = '/' + apartment_slug + '/';
        }
    }).fail(function() {
            location.href = '/' + apartment_slug + '/';
    });
}

function formatPrice(price) {
    if (price !== undefined) {
        price = parseFloat(price);

        return price.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
    }
}

function makeOptionActive(option_key, step) {
    if (step === 1) {
        for (const [key, value] of Object.entries(state['steps'][1])) {
            if (value['floor_id'] === state['steps'][step][option_key]['floor_id']) {
                state['steps'][1][key]['active'] = 0;
                setOptionInactive('.step_' + step + ' button[value="' + key + '"]');
            }
        }
        state['steps'][1][option_key]['active'] = 1;
        setOptionActive('.step_' + step + ' button[value="' + option_key + '"]');
    } else {
        if (state['steps'][step][option_key]['option_type'] === "single") {
            if (step === 3) {
                for (const [key, value] of Object.entries(state['steps'][step])) {
                    if (value['component_id'] === state['steps'][step][option_key]['component_id']) {
                        state['steps'][step][key]['active'] = 0;
                        setOptionInactive('.step_' + step + ' a[data-value="' + key + '"]');
                    }
                }
            } else {
                for (const [key, value] of Object.entries(state['steps'][step])) {
                    if (value['room_id'] === state['steps'][step][option_key]['room_id'] &&
                        value['component_id'] === state['steps'][step][option_key]['component_id']
                    ) {
                        state['steps'][step][key]['active'] = 0;
                        setOptionInactive('.step_' + step + ' a[data-value="' + key + '"]');
                    }
                }
            }
            state['steps'][step][option_key]['active'] = 1;
            setOptionActive('.step_' + step + ' a[data-value="' + option_key + '"]');
        } else {
            if (state['steps'][step][option_key]['active'] == 1) {
                state['steps'][step][option_key]['active'] = 0;
                setOptionInactive('.step_' + step + ' a[data-value="' + option_key + '"]');
            } else {
                state['steps'][step][option_key]['active'] = 1;
                setOptionActive('.step_' + step + ' a[data-value="' + option_key + '"]');
            }
        }
    }
}

function showOverlayImageOfOption(option_key, step) {
    stateOfElement = state['steps'][step][option_key];
    if (stateOfElement['overlay_img'] !== undefined) {
        $('.option_image_overlay.component_' + stateOfElement['component_id'] + ' img').attr('src', stateOfElement['overlay_img']);
        stateOfElement['active'] = 1;
    }
}

function showImageOfGroundPlan(option_key) {
    if (state['steps'][1][option_key]['img'] != "") {
        $('.groundplan_image_' + state['steps'][1][option_key]['floor_id'] + ' img').attr("src",state['steps'][1][option_key]['img']);
    } else {
        $('.groundplan_image_' + state['steps'][1][option_key]['floor_id'] + ' img').attr("src",'/images/placeholder.svg');
    }
}

function showImages() {
    if (state['steps'][1] !== undefined) {
        for (const [option_key, option] of Object.entries(state['steps'][1])) {
            if (option.active == 1) {
                showImageOfGroundPlan(option_key);
            }
        }
    }
    if (state['steps'][2] !== undefined) {
        for (const [option_key, option] of Object.entries(state['steps'][2])) {
            if (option.active == 1) {
                showOverlayImageOfOption(option_key, 2);
            }
        }
    }
}

function downloadPdf(link, pdfName) {
    $('.pdf-download').hide();
    $('.pdf-loading').show();
    $.ajax({
        url: link,
        method: 'GET',
        xhrFields: {
            responseType: 'blob'
        },
        success: function (data) {
            var a = document.createElement('a');
            var url = window.URL.createObjectURL(data);
            a.href = url;
            a.download = pdfName;
            document.body.append(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
            $('.pdf-loading').hide();
            $('.pdf-download').show();
        }
    });
}

function copyToClipboard(element) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).val()).select();
    document.execCommand("copy");
    $temp.remove();
    return false;
}

function footerHandler(){
    $('.toggle-footer').click(function(){
        $('footer').toggleClass('open');
    });
}

$(document).ready(function() {
    $(window).scroll(function() {
        if ($(document).width() < 1200) {
            $('.div_room').each(function() {
                $('.base_img', this).removeClass('mobile-fixed');
                $('.base_img', this).removeClass('reduced-opacity')
                var scrollTop = $(document).scrollTop() + $('.main-nav').outerHeight();
                if (scrollTop > $(this).offset().top
                    && scrollTop < $(this).offset().top + $(this).height()) {
                    $('.base_img.preview-img-placeholder', this).addClass('mobile-fixed');
                    $('.base_img.preview-img', this).addClass('reduced-opacity');
                }
            });
        }
    });

    $('.copy-btn').click(function(){
        copyToClipboard('#configuration_link');
        $('#copiedModal').modal('show');
        setTimeout(function () {
            $('#copiedModal').modal('hide');
        }, 3000);
    });

    if (typeof(state) !== 'undefined') {
        recalculatePrices();
        setOptionsActive();
        showImages();
        footerHandler();
        $('.ground-plan-option').click(function () {
            makeOptionActive(this.value, 1);
            showImageOfGroundPlan(this.value);
            recalculatePrices();
        });
        $('.interior-design').click(function () {
            makeOptionActive($(this).attr('data-value'), 2);
            showOverlayImageOfOption($(this).attr('data-value'), 2);
            recalculatePrices();
        });
        $('.additional-option').click(function () {
            makeOptionActive($(this).attr('data-value'), 3);
            recalculatePrices();
        });
        $('.preset').click(function() {
            $('.preset').removeClass('active');
            $(this).addClass('active');
            $('.preset_'+$(this).attr('data-preset')).each(function() {
                makeOptionActive($(this).attr('data-value'), 2);
                showOverlayImageOfOption($(this).attr('data-value'), 2);
            })
            recalculatePrices();
        });
        $('[data-main-option]').click(function(){
            let mainOption = $(this).data('main-option');
            mainOption = mainOption.toLowerCase();
            
            $(`[data-main-option="${mainOption}"]`).each(function(){                
                makeOptionActive($(this).attr('data-value'), 2);
                showOverlayImageOfOption($(this).attr('data-value'), 2);
            });

            recalculatePrices();
        });
        $('.go-to-main-option').click(function(){
            let mainOption = $(this).data('main-option-value');

            let scrollTop = $(`[data-main-option="${mainOption}"]`).parent().parent().offset().top - 60;

            if($(window).width() < 1200){
                scrollTop = scrollTop - $('.mobile-fixed').height();
            }

            $('html, body').animate({
                scrollTop: scrollTop
            }, 1000);
        });
        $('.save-state').click(function() {
            saveState($(this).attr('data-href'), state['apartment_slug']);
        });
        $('.download-pdf').click(function() {
            downloadPdf($(this).attr('data-href'), $(this).attr('data-pdf-download-name'));
        });
        $('.tooltip-container').tooltip();
        $('.tooltip-container').click(function(e){
            e.stopPropagation();
        });
        $('.modal-toggle').click(function(e){
            $($(this).attr('data-target')).modal('show');
            return false;
        });

        $('.show-apartment-details').click(function(){
            if($(window).width() <= 992){
                if($('#collapseApartmentDetails').css('display') == 'none'){
                    $('#collapseApartmentDetails').show();
                    $('.show-apartment-details svg').css('transform','rotate(90deg)');
                }else{
                    $('#collapseApartmentDetails').hide();
                    $('.show-apartment-details svg').css('transform','rotate(270deg)');
                }
            }
        });
    }
});
